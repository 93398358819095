import mixpanel from "mixpanel-browser";

export function track(eventLabel, options = {}) {
  try {
    mixpanel.track(eventLabel, {
      system_id: window.location.host,
      ...options,
    });
  } catch (error) {
    console.log(error);
  }
}

export default {
  track,
};
