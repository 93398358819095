import { createApp } from "vue";
import { createPinia } from "pinia";
import { setupPlausible } from "./services/plausible";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import AppDev from "./AppDev.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import mixpanel from "mixpanel-browser";

import VueApexCharts from "vue3-apexcharts";

// Add plugins
setupPlausible();

if (import.meta.env.VITE_MIXPANEL_TOKEN) {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    debug: import.meta.env.DEV,
  });
}

const isSetupRoute = () =>
  window.location.href.includes("/general-settings/heroku");
const isStandaloneApp = import.meta.env.VITE_IS_STANDALONE === "true";

const pinia = createPinia();

if (isSetupRoute() && !isStandaloneApp) {
  document.getElementById("appDevContainer").remove();
  const pinia = createPinia();
  const app = createApp(App);
  app.use(vuetify);
  app.use(VueApexCharts);
  app.use(pinia);
  app.use(router);
  app.mount("#app");
} else {
  if (isStandaloneApp) {
    const appDev = createApp(AppDev);
    appDev.use(vuetify);
    appDev.use(VueApexCharts);
    appDev.use(pinia);
    appDev.mount("#appDev");
  }

  window.addEventListener("message", async (event) => {
    if (event.data) {
      const { userId } = event.data;

      if (userId) {
        const currentVersion = "1";
        if (localStorage.getItem("version") !== currentVersion) {
          localStorage.clear();
        }
        localStorage.setItem("version", currentVersion);
        localStorage.setItem("contextMessage", JSON.stringify(event.data));
        const app = createApp(App);

        if (import.meta.env.PROD) {
          Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
              Sentry.browserTracingIntegration({ router }),
              Sentry.replayIntegration(),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", /^\//],
            tracePropagationTargets: [/^\//],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
          });
        }

        app.use(vuetify);
        app.use(VueApexCharts);
        app.use(pinia);
        app.use(router);
        app.mount("#app");

        document.getElementById("appDevContainer").remove();
      }
    }
  });
}
