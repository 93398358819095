export const setupPlausible = () => {
  var script1 = document.createElement("script");
  script1.setAttribute("defer", "defer");

  let globalSite = "qa.activeprime.dev";

  if (import.meta.env.VITE_ENV === "release") {
    globalSite = "prod.activeprime.io";
  }

  if (import.meta.env.MODE === "development") {
    script1.setAttribute("data-domain", "qa.activeprime.dev");
  } else {
    script1.setAttribute(
      "data-domain",
      `${window.location.host},${globalSite}`,
    );
  }

  if (import.meta.env.MODE === "development") {
    script1.src =
      "https://analytics.activeprime.io/js/script.outbound-links.local.js";
  } else {
    script1.src =
      "https://analytics.activeprime.io/js/script.outbound-links.js";
  }

  document.head.appendChild(script1);

  var script2 = document.createElement("script");
  script2.text =
    "window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }";
  document.head.appendChild(script2);
};

export const events = {
  DUPEGRID_MERGE_CLICKED: "DupeGrid - Merge Clicked",
  DUPEGRID_MERGE_CANCELLED: "DupeGrid - Merge Cancelled",
  DUPEGRID_MERGE_CONFIRMED: "DupeGrid - Merge Confirmed",

  DUPEGRID_SEND_MESSAGE_CLICKED: "DupeGrid - Send Message Clicked",
  DUPEGRID_SEND_MESSAGE_CONFIRMED: "DupeGrid - Send Message Confirmed",

  DUPEGRID_TUTORIAL_CLICKED: "DupeGrid - Tutorial Clicked",
  DUPEGRID_NOTIFICATIONS_CLICKED: "DupeGrid - Notifications Clicked",
};
