import axios from "axios";
import { useSession } from "@/stores/session";

let devhttpBaseURL = "/api";
if (import.meta.env.VITE_PROVISIONING_API_URL) {
  devhttpBaseURL = import.meta.env.VITE_PROVISIONING_API_URL + "api";
}
const devhttp = axios.create({
  baseURL: devhttpBaseURL,
  timeout: 60000,
  headers: { "x-api-key": import.meta.env.VITE_PROVISIONING_API_TOKEN },
  withCredentials: true,
});

const http = axios.create({
  baseURL: "/api/v0",
  timeout: 60000,
  withCredentials: true,
});

// Request helpers ($get, $post, ...) to retrieve data directly
for (const method of [
  "request",
  "delete",
  "get",
  "head",
  "options",
  "post",
  "put",
  "patch",
]) {
  http["$" + method] = function () {
    return this[method].apply(this, arguments).then((res) => res && res.data);
  };
}

http.interceptors.request.use(
  async (config) => {
    const sessionStore = useSession();
    const env = localStorage.getItem("env");

    if (sessionStore.jwt) {
      if (env) {
        config.baseURL = "/" + env + config.baseURL;
      }

      config.headers = {
        Authorization: `Bearer ${sessionStore.jwt}`,
        ...config.headers,
      };
    } else if (import.meta.env.VITE_IS_STANDALONE === "true") {
      const token = localStorage.getItem("apiKey");

      config.baseURL = "/" + env + config.baseURL;

      config.headers = {
        Authorization: `token:${token}`,
        ...config.headers,
      };
    } else {
      config.headers = {
        Authorization: `heroku_token ${sessionStore.herokuToken}`,
        ...config.headers,
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

async function bypassHandler(response) {
  const sessionStore = useSession();
  sessionStore.setLastRequestTimestamp();

  return response;
}

async function errorHandler(error) {
  if (error && error.response && error.response.status) {
    const status = error.response.status;

    if (status === 403 || status === 401) {
      const sessionStore = useSession();
      await sessionStore.setSessionExpired();
    }
  }

  return Promise.reject(error);
}

http.interceptors.response.use(bypassHandler, errorHandler);

export { devhttp };

export default http;
