export const reviewStates = {
  NOT_REVIEWED: "not_reviewed",
};

export const dupeSetsStates = {
  CREATED: "created",
  FINISHED: "write_finished",
  FAILED: "write_failed",
  QUEUED: "write_queued",
  MODIFIED: "modified",
  VIEWED: "viewed",
  HIGH_MCS_FINISHED: "high_mcs_write_finished",
  HIGH_MCS_FAILED: "high_mcs_write_failed",
  HIGH_MCS_QUEUED: "high_mcs_write_queued",
  NOTIFIED: "notified_other",
  WRITE_UNDO_QUEUED: "write_undo_queued",
  WRITE_UNDO_FINISHED: "write_undo_finished",
  WRITE_UNDO_FAILED: "write_undo_failed",
  ARCHIVED: "archived",
};

export const colorByObject = {
  account: "#5867E8",
  contact: "#9602C7",
  lead: "#1B96FF",
  opportunity: "#FF5D2D",
  location: "#3BA755",
};

export const otherColorForObject = [
  // "#795548",
  // "#FF9800",
  // "#C62828",
  "#c23531",
  "#2f4554",
  "#61a0a8",
  "#d48265",
  "#91c7ae",
  "#749f83",
  "#ca8622",
  "#bda29a",
  "#6e7074",
  "#546570",
];

export const colorByIssue = {
  damaged: "#9C27B0", //purple
  format: "#795548", // brown
  invalid: "#FF9800", // orange
  junk: "#4CAF50", // green
  misplaced: "#0277BD", // blue
  missing: "#C62828", // red
  contains: "#000000",
  notcontains: "#000000",
};

export const colorByScore = {
  high: "ap-green",
  medium: "ap-yellow",
  low: "ap-orange",
};
