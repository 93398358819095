import { defineStore, acceptHMRUpdate } from "pinia";
import { useNotifications } from "./notifications";
import mixpanel from "@/utils/mixpanel";
import { ref, reactive, computed } from "vue";
import * as connectionsAPI from "../api/connections";

export const useConnections = defineStore("connections", () => {
  const notification = useNotifications();

  const connections = ref(
    JSON.parse(localStorage.getItem("connections")) || [],
  );

  const requesting = reactive({
    create: false,
    update: false,
    connectionObjectsList: false,
  });
  const errors = reactive({
    connectionObjectsList: false,
  });

  const herokuConnection = computed(() => {
    return connections.value.find((el) => el.systemType === "heroku");
  });
  const salesforceConnection = computed(() => {
    return connections.value.find((el) => el.systemType === "sfdc");
  });
  const crmObjects = ref(null);

  const missingConnections = computed(() =>
    connections.value.filter((connection) => connection.isMissing),
  );
  const isSomeConnectionMissing = computed(
    () => missingConnections.value.length > 0,
  );
  const disconnectedConnections = computed(() =>
    connections.value.filter(
      (connection) => connection.status === "disconnected",
    ),
  );
  const isSomeConnectionDisconnected = computed(
    () => disconnectedConnections.value.length > 0,
  );
  const disconnectedConnectionsForUnknownReasons = computed(() =>
    connections.value.filter(
      (connection) => connection.authStatus === "unknown",
    ),
  );

  async function setConnectionsList(list) {
    connections.value = list;
    localStorage.setItem("connections", JSON.stringify(list));
  }

  async function create(payload) {
    try {
      requesting.create = true;

      const connection = await connectionsAPI.createConnection(payload);

      if (connection.systemType === "heroku") {
        mixpanel.track("Created Heroku Connection");
      } else if (connection.systemType === "sfdc") {
        mixpanel.track("Created Salesforce Connection");
      }

      connections.value = connections.value.map((el) => {
        if (connection.systemType === el.systemType) {
          return connection;
        } else {
          return el;
        }
      });

      notification.setSuccessNotificationMessage(
        "Connection successfully created.",
      );
    } catch (error) {
      if (error.response.status === 422) {
        notification.setErrorNotificationMessage(
          "Invalid credentials. Please revise them and try again.",
        );
      } else {
        notification.setErrorNotificationMessage();
      }

      throw error;
    } finally {
      requesting.create = false;
    }
  }

  async function update(payload) {
    try {
      requesting.update = true;

      const connection = await connectionsAPI.updateConnection(payload);

      connections.value = connections.value.map((el) => {
        if (connection.id === el.id) {
          return connection;
        } else {
          return el;
        }
      });

      notification.setSuccessNotificationMessage(
        "Connection successfully updated.",
      );
    } catch (error) {
      if (error.response.status === 422) {
        notification.setErrorNotificationMessage(
          "Invalid credentials. Please revise them and try again.",
        );
      } else {
        notification.setErrorNotificationMessage();
      }

      throw error;
    } finally {
      requesting.update = false;
    }
  }

  async function disconnect(connection) {
    try {
      connections.value = connections.value.map((el) => {
        if (connection.id === el.id) {
          return {
            ...connection,
            isProcessing: true,
          };
        }
        return el;
      });

      const updatedConnection = await connectionsAPI.disconnectConnection(
        connection.id,
      );

      connections.value = connections.value.map((el) => {
        if (updatedConnection.id === el.id) {
          return updatedConnection;
        } else {
          return el;
        }
      });

      notification.setSuccessNotificationMessage("Successfully disconnected.");
    } catch (error) {
      notification.setErrorNotificationMessage();
      throw error;
    }
  }

  async function connect(connection) {
    try {
      connections.value = connections.value.map((el) => {
        if (connection.id === el.id) {
          return {
            ...connection,
            isProcessing: true,
          };
        }
        return el;
      });

      const updatedConnection = await connectionsAPI.connectConnection(
        connection.id,
      );

      connections.value = connections.value.map((el) => {
        if (updatedConnection.id === el.id) {
          return updatedConnection;
        } else {
          return el;
        }
      });

      notification.setSuccessNotificationMessage("Successfully connected.");
    } catch (error) {
      notification.setErrorNotificationMessage();
      throw error;
    }
  }

  async function tryToConnect() {
    const requests = [];
    disconnectedConnectionsForUnknownReasons.value.forEach((connection) =>
      requests.push(connect(connection.id)),
    );
    await Promise.all(requests);
  }

  async function remove(connection) {
    try {
      connections.value = connections.value.map((el) => {
        if (connection.id === el.id) {
          return {
            ...connection,
            isProcessing: true,
          };
        }
        return el;
      });

      await connectionsAPI.removeConnection(connection.id);

      connections.value = connections.value.map((el) => {
        if (connection.systemType === el.systemType) {
          return { ...connection, isMissing: true };
        } else {
          return el;
        }
      });

      notification.setSuccessNotificationMessage(
        "Connection successfully deleted.",
      );
    } catch (error) {
      notification.setErrorNotificationMessage();
      throw error;
    }
  }

  async function getConnectionObjects(connectionId) {
    try {
      errors.connectionObjectsList = false;
      requesting.connectionObjectsList = true;

      crmObjects.value =
        await connectionsAPI.getConnectionObjects(connectionId);
    } catch (error) {
      errors.connectionObjectsList = true;
      throw error;
    } finally {
      requesting.connectionObjectsList = false;
    }
  }

  return {
    connections,
    herokuConnection,
    salesforceConnection,

    missingConnections,
    isSomeConnectionMissing,
    disconnectedConnections,
    isSomeConnectionDisconnected,
    disconnectedConnectionsForUnknownReasons,

    crmObjects,

    requesting,
    errors,
    setConnectionsList,
    create,
    update,
    disconnect,
    connect,
    tryToConnect,
    remove,
    getConnectionObjects,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConnections, import.meta.hot));
}
