import http from "./http";
import { useStats } from "@/stores/stats";

export const getMetrics = () => {
  const statsStore = useStats();
  const issuesToConsider = Object.keys(statsStore.issuesMetadata.issueTypes);

  return http.$get("/dashboard/metrics").then((response) => {
    const overviewStats = {
      clean: response.quality.percent.clean,
      dirty: response.quality.percent.dirty,
    };
    const duplicateSetsToReview = [];
    const mergedDuplicatesSets = [];
    const undoMergeErrors = [];
    const mergeErrors = [];
    const dataToUpdateByObject = [];
    const dataToUpdateByIssue = [];
    const fixedIssues = [];
    const fixErrors = [];
    const undoFixErrors = [];

    Object.keys(response.dupeset_by_directors).forEach((director) => {
      const directorTotalSets =
        response.dupeset_by_directors[director].open.total;
      const directorTotalManual =
        response.dupeset_by_directors[director].written.success.manual;
      const directorTotalUndone =
        response.dupeset_by_directors[director].written.undone;
      const directorTotalUndoFailed =
        response.dupeset_by_directors[director].written.undo_failed;
      const directorTotalAuto =
        response.dupeset_by_directors[director].written.success.auto;
      const directorTotalMerged =
        directorTotalManual + directorTotalAuto + directorTotalUndone;
      const directorTotalFailed =
        response.dupeset_by_directors[director].written.failed;

      if (directorTotalSets) {
        duplicateSetsToReview.push({
          director,
          count: directorTotalSets,
        });
      }

      if (directorTotalMerged) {
        mergedDuplicatesSets.push({
          director,
          countManual: directorTotalManual,
          countAuto: directorTotalAuto,
          countUndone: directorTotalUndone,
        });
      }

      if (directorTotalUndoFailed) {
        undoMergeErrors.push({
          director,
          count: directorTotalUndoFailed,
        });
      }

      if (directorTotalFailed) {
        mergeErrors.push({
          director,
          count: directorTotalFailed,
        });
      }
    });

    issuesToConsider.forEach((issue) => {
      const issueTemp = { issue, objects: [] };

      Object.keys(response.recordissue).forEach((object) => {
        if (response.recordissue[object].open.by_issue[issue]) {
          issueTemp.objects.push({
            object,
            count: response.recordissue[object].open.by_issue[issue],
          });
        }
      });

      dataToUpdateByIssue.push({
        ...issueTemp,
        totalRecords: issueTemp.objects.reduce(
          (acc, current) => acc + current.count,
          0,
        ),
      });
    });

    Object.keys(response.recordissue).forEach((object) => {
      const objectTotalManual =
        response.recordissue[object].written.success.manual;
      const objectTotalAuto = response.recordissue[object].written.success.auto;
      const objectTotalUndone = response.recordissue[object].written.undone;
      const objectTotalFixed =
        objectTotalManual + objectTotalAuto + objectTotalUndone;
      const objectTotalFailed = response.recordissue[object].written.failed;
      const objectTotalUndoFailed =
        response.recordissue[object].written.undo_failed || 0;

      const dataToUpdate = [];

      Object.keys(response.recordissue[object].open.by_issue).forEach(
        (issue) => {
          if (issuesToConsider.includes(issue)) {
            dataToUpdate.push({
              issue,
              count: response.recordissue[object].open.by_issue[issue],
            });
          }
        },
      );

      dataToUpdateByObject.push({
        totalRecords: response.recordissue[object].open.total,
        object,
        issues: dataToUpdate,
      });

      if (objectTotalFixed) {
        fixedIssues.push({
          object,
          countManual: objectTotalManual,
          countAuto: objectTotalAuto,
          countUndone: objectTotalUndone,
        });
      }

      if (objectTotalFailed) {
        fixErrors.push({
          object,
          count: objectTotalFailed,
        });
      }

      if (objectTotalUndoFailed) {
        undoFixErrors.push({
          object,
          count: objectTotalUndoFailed,
        });
      }
    });

    return {
      overviewStats,
      duplicateSetsToReview,
      mergedDuplicatesSets,
      undoMergeErrors,
      mergeErrors,
      dataToUpdateByObject,
      dataToUpdateByIssue,
      fixedIssues,
      fixErrors,
      undoFixErrors,
    };
  });
};
