import http from "./http";

export const getRoles = () => {
  return http.$get("/admin/roles").then((response) =>
    response.data.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    }),
  );
};
